import { render, staticRenderFns } from "./SelectProfile.vue?vue&type=template&id=3125a5f2&scoped=true&"
import script from "./SelectProfile.js?vue&type=script&lang=js&"
export * from "./SelectProfile.js?vue&type=script&lang=js&"
import style0 from "./SelectProfile.scss?vue&type=style&index=0&id=3125a5f2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3125a5f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VContainer,VFlex,VHover,VLayout})
