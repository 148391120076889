import Vue from "vue";

export default Vue.extend({
  name: "SelectProfile",
  props: {
    roles: {
      type: Array,
      default: () => ["ADMINISTRATOR"]
    }
  },
  components: {
    LoginHeader: () => import("../../../components/Headers/LogoRed")
  },
  computed: {
    filterRol() {
      const root = this.roles.indexOf("ROOT");
      const owner = this.roles.indexOf("OWNER");
      const geochef = this.roles.indexOf("ADMIN_GEOCHEF");
      if (root !== -1) this.roles.splice(root, 1);
      if (owner !== -1) this.roles.splice(owner, 1);
      if (geochef !== -1) this.roles.splice(geochef, 1);
      return this.roles;
    }
  },
  methods: {
    selectedRol(rol) {
      this.$emit("selectRol", rol);
      localStorage.setItem("selectedRol", rol);
    }
  }
});
